import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const URL = 'https://cms.nicholaslazzerini.com/nicholaslazzerini/graphql'

const httplink = createHttpLink({
  uri: operation => `${URL}?name=${operation.operationName}`
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httplink),
  cache: new InMemoryCache(),
})

export default client
