import React, { Fragment } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

const HomeContent = React.lazy(() => import('@contents/Home'))
const ProjectsContent = React.lazy(() => import('@contents/Projects'))
const AboutContent = React.lazy(() => import('@contents/About'))
const ProjectDetails = React.lazy(() => import('@contents/ProjectDetails'))
const NotFound = React.lazy(() => import('@contents/404'))
// const Courtesy = React.lazy(() => import('@containers/Courtesy'))

export const Routes: React.FC<RouteComponentProps> = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path={'/home'} component={HomeContent} />
        <Route exact path={'/projects/:filter'} component={ProjectsContent} />
        <Route exact path={'/projects/:filter/:id'} component={ProjectDetails} />
        <Route exact path={'/about'} component={AboutContent} />
        {/* <Route exact path={`${match.url}/courtesy`} component={Courtesy} /> */}
        <Route component={NotFound} />
      </Switch>
    </Fragment>
  )
}
