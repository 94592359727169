import { IconProps } from '@customTypes/Components'

export const Eye: React.FC<IconProps> = ({ size, fill = 'none' }) => (
  <svg width={size} height={size} viewBox="0 0 42 42" fill={fill} xmlns="http://www.w3.org/2000/svg" >
    <path d="M36.821 19.0584C37.6368 20.1256 37.6368 21.5662 36.821 22.6316C34.2513 25.9862 28.0861 32.8932 20.8881 32.8932C13.6902 32.8932 7.52494 25.9862 4.95523 22.6316C4.5583 22.1206 4.34283 21.492 4.34283 20.845C4.34283 20.198 4.5583 19.5694 4.95523 19.0584C7.52494 15.7039 13.6902 8.79681 20.8881 8.79681C28.0861 8.79681 34.2513 15.7039 36.821 19.0584V19.0584Z" stroke="#23708E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.8881 26.0086C23.7399 26.0086 26.0516 23.6968 26.0516 20.8451C26.0516 17.9934 23.7399 15.6816 20.8881 15.6816C18.0364 15.6816 15.7246 17.9934 15.7246 20.8451C15.7246 23.6968 18.0364 26.0086 20.8881 26.0086Z" stroke="#23708E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)


