import { Modal } from '@components/Modal'
import { GlobalModal } from 'react-global-modal'

export const useGlobalModal = () => {
  const open = () => GlobalModal.push({
    component: Modal,
    className: 'contact-modal',
    modalSize: 'md',
    hideHeader: true,
    hideCloseIcon: true
  })

  const close = () => GlobalModal.pop()

  return {
    open,
    close
  }

}
