import { useEffect } from 'react'

export const useFormAlarmClock = () =>
	useEffect(() => {
		fetch('https://backend-utils.onrender.com/wakeup', {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		})
	}, [])