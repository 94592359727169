import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'
import { clampValues, phone, pxToRem } from '../libs'

const StyledButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.buttonText};
  border: none;
  padding: ${pxToRem(24)} ${pxToRem(24)};
  font-size: ${clampValues(18, 24)};
  border-radius: ${pxToRem(16)};
  cursor: pointer;
  width: fit-content;
  ${phone(`
    width: 100%;
  `)}
`

export const CustomButton: React.FC = ({ children }) => {
  return <StyledButton>{children}</StyledButton>
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  padding: ${pxToRem(24)} ${pxToRem(24)};
  font-size: ${clampValues(18, 24)};
  border-radius: ${pxToRem(16)};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
  ${phone(`
    width: 100%;
    justify-content: center;
  `)}
`

export const CustomLink: React.FC<LinkProps> = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>
}
