import { IconProps } from '@customTypes/Components'

export const Logo: React.FC<IconProps> = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" width={size} height={size}>
    <defs />
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 600h600V0H0z" />
      </clipPath>
    </defs>
    <g transform="matrix(1.33333 0 0 -1.33333 0 800)">
      <path
        fill="#2c738b"
        d="M510.5 300c0-116.256-94.244-210.5-210.5-210.5S89.5 183.744 89.5 300 183.744 510.5 300 510.5 510.5 416.256 510.5 300"
      />
      <path
        fill="#fff"
        d="M162.125 410.397h12.886l69.304-110.575h.349v110.575h11.318V284.673h-14.278l-67.912 108.659h-.349v-108.66h-11.318z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="4.837"
        d="M221.43 140.154l163.14 317.691"
      />
      <path
        fill="#fff"
        d="M343.94 305.764h17.239V195.538h58.509V180.04H343.94z"
      />
    </g>
  </svg>
)
