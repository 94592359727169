import { IconProps } from '@customTypes/Components'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const LeftArrow: React.FC<IconProps> = ({ size }) => {
  const theme = useContext(ThemeContext)
  return <svg width={size} height={size} viewBox="0 0 42 42" fill={theme.colors.icons} xmlns="http://www.w3.org/2000/svg" >
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 20C2.5 24.6413 4.34374 29.0925 7.62563 32.3744C10.9075 35.6563 15.3587 37.5 20 37.5C24.6413 37.5 29.0925 35.6563 32.3744 32.3744C35.6563 29.0925 37.5 24.6413 37.5 20C37.5 15.3587 35.6563 10.9075 32.3744 7.62563C29.0925 4.34374 24.6413 2.5 20 2.5C15.3587 2.5 10.9075 4.34374 7.62563 7.62563C4.34374 10.9075 2.5 15.3587 2.5 20ZM40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 0 25.3043 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20ZM28.75 18.75C29.0815 18.75 29.3995 18.8817 29.6339 19.1161C29.8683 19.3505 30 19.6685 30 20C30 20.3315 29.8683 20.6495 29.6339 20.8839C29.3995 21.1183 29.0815 21.25 28.75 21.25H14.2675L19.635 26.615C19.7512 26.7312 19.8434 26.8692 19.9063 27.021C19.9692 27.1729 20.0016 27.3356 20.0016 27.5C20.0016 27.6644 19.9692 27.8271 19.9063 27.979C19.8434 28.1308 19.7512 28.2688 19.635 28.385C19.5188 28.5012 19.3808 28.5934 19.229 28.6563C19.0771 28.7192 18.9144 28.7516 18.75 28.7516C18.5856 28.7516 18.4229 28.7192 18.271 28.6563C18.1192 28.5934 17.9812 28.5012 17.865 28.385L10.365 20.885C10.2486 20.7689 10.1562 20.6309 10.0932 20.4791C10.0302 20.3272 9.99777 20.1644 9.99777 20C9.99777 19.8356 10.0302 19.6728 10.0932 19.5209C10.1562 19.3691 10.2486 19.2311 10.365 19.115L17.865 11.615C18.0997 11.3803 18.4181 11.2484 18.75 11.2484C19.0819 11.2484 19.4003 11.3803 19.635 11.615C19.8697 11.8497 20.0016 12.1681 20.0016 12.5C20.0016 12.8319 19.8697 13.1503 19.635 13.385L14.2675 18.75H28.75Z" fill={theme.colors.icons} />
  </svg>
}
