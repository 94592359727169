import { ApolloProvider } from '@apollo/client'
import { BaseContainer } from '@components/BaseContainer'
import { Header } from '@components/Header'
import { Loading } from '@components/Loading'
import { useDarkMode } from '@hooks/useDarkMode'
import client from '@libs/apolloClient'
import { Suspense, useEffect } from 'react'
import { GlobalModal, GlobalModalWrapper } from 'react-global-modal'
import 'react-global-modal/dist/style.css'
import {
  BrowserRouter as Router, Route, RouteComponentProps, Switch
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { NavigationProvider } from './providers/NavigationProvider'
import { Routes } from './routes'
import { darkMode, GlobalStyles, lightMode } from './theme'

const BaseWrapper: React.FC<RouteComponentProps> = props => {
  const { theme, setMode } = useDarkMode()
  const themeMode = theme === 'light' ? lightMode : darkMode
  let globalModalRef: any = null

  const changeTheme = (isDark: boolean) => {
    setMode(isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    GlobalModal.setUpModal(globalModalRef)
  }, [globalModalRef])

  return (
    <NavigationProvider>
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        {/* <LoadingPage loading={showLoader}> */}
        <Suspense fallback={<Loading />}>
          <BaseContainer>
            <Header toggleTheme={(isDark: boolean) => changeTheme(isDark)} />
            <Routes {...props} />
            <GlobalModalWrapper ref={(el) => (globalModalRef = el)} />
          </BaseContainer>
        </Suspense>
        {/* </LoadingPage> */}
      </ThemeProvider>

    </NavigationProvider>
  )
}

function App() {
  return (

    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/" render={props => <BaseWrapper {...props} />} />
        </Switch>
      </Router>
    </ApolloProvider >
  )
}

export default App
