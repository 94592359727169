import { PAGE_PADDING_REM } from '@theme/index'
import styled from 'styled-components'
import { clampValues, phone, pxToRem } from '../libs'

const BaseContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  max-width:${pxToRem(2100)};
  max-height:${pxToRem(1800)};
  margin: 0 auto;
  border: 3px solid ${({ theme }) => theme.colors.pageBorder};
  padding: ${clampValues(15, parseInt(PAGE_PADDING_REM) / 2)} ${clampValues(20, parseInt(PAGE_PADDING_REM))};
`

export const BaseContainer: React.FC = ({ children }) => {
  return <BaseContainerStyled>{children}</BaseContainerStyled>
}

interface FlexContainerProps {
  align?: 'center' | 'flex-start' | 'flex-end';
}

const FlexContainerStyled = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${pxToRem(115)}); //115px height of the header
  justify-content: ${(props) => props.align};
  ${phone(`
    overflow: auto;
    padding-right: ${pxToRem(8)};
    justify-content: flex-start;
  `)}
`

export const FlexContainer: React.FC<FlexContainerProps> = ({
  children,
  align = 'center',
}) => <FlexContainerStyled align={align}>{children}</FlexContainerStyled>
