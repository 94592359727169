import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import config from '../config'

export interface WindowDimensions {
  height: number
  width: number
}

export const pxToRem = (pxValue: number) => {
  const ratio = config.FONT_SIZE

  if (Array.isArray(pxValue)) {
    pxValue = pxValue[0]
  }

  return `${pxValue / ratio}rem`
}

export const BREAKPOINTS = {
  tablet: '@media (max-width: 980px)',
  mobile: '@media (max-width: 768px)',
}

export const useMediaQuery = (query: string) => {
  const [queryMatch, setQueryMatch] = useState<boolean | null>(null)

  useEffect(() => {
    const handleQueryListener = debounce(() => {
      const updatedMatches = window.matchMedia(query)
      setQueryMatch(updatedMatches.matches)
    }, 250)

    handleQueryListener()
    window.addEventListener('resize', handleQueryListener)

    return () => {
      window.removeEventListener('resize', handleQueryListener)
    }
  }, [query])

  return queryMatch
}


export const uniqueArray = (a: any) => new Set(a.map((o: any) => o[0]))

export const useWindowResizeListener = () => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    height: 0,
    width: 0,
  })

  useEffect(() => {
    const handleWindowResize = debounce(() => {
      const width = window.innerWidth
      const height = window.innerHeight
      setWindowDimensions({
        height,
        width,
      })
    }, 250)

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return windowDimensions
}

// todo: change to 16 when html font-size is changed
const PXTOREM_RATIO = 22

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minPxSize: number,
  maxPxSize: number
) => {
  const minWidth = minWidthPx / PXTOREM_RATIO
  const maxWidth = maxWidthPx / PXTOREM_RATIO

  const minRemWidth = minPxSize / PXTOREM_RATIO
  const maxRemWidth = maxPxSize / PXTOREM_RATIO

  const slope = (maxRemWidth - minRemWidth) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minRemWidth

  const calculatedPreferredValue = `${yAxisIntersection.toFixed(4)}rem + ${(slope * 100).toFixed(
    4
  )}vw`

  return `clamp(${minRemWidth}rem, ${calculatedPreferredValue}, ${maxRemWidth}rem)`
}

export const clampValues = (min: number, max: number) => {
  return clampBuilder(300, 1920, min, max)
}

export const clampValuesLandscape = (min: number, max: number) => {
  return clampBuilder(1024, 1366, min, max)
}

export enum Device {
  fullhd = 'fullhd',
  tablet = 'tablet',
  tabletMini = 'tabletMini',
  phone = 'phone',
}
export const DeviceMap: { [key in keyof typeof Device]: string } = {
  [Device.fullhd]: '(max-width: 1199.98px)',
  [Device.tablet]: '(max-width: 991.98px)',
  [Device.tabletMini]: '(max-width: 767.98px)',
  [Device.phone]: '(max-width: 575.98px)'
}

export function landscape(device: Device, css: string): string
export function landscape(css: string): string
export function landscape(cssOrDevice: Device | string, css?: string): string {
  if (Object.values(Device).indexOf(cssOrDevice as Device) > -1) {
    return `
      body.landscape-navigation & {
        @media ${DeviceMap[cssOrDevice as Device]} {${css}}
      }
    `
  } else {
    return `body.landscape-navigation & {${cssOrDevice}}`
  }
}

export function tabletPortrait(css: string): string
export function tabletPortrait(device: Device, css: string): string
export function tabletPortrait(cssOrDevice: Device | string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device ? DeviceMap[cssOrDevice as Device] : DeviceMap[Device.tablet]

  const cssProperties = css || cssOrDevice

  return `
  @media ${mediaQuery} and (orientation: portrait) {
    ${cssProperties}
  }
`
}

export function tablet(device: string, css: string): string
export function tablet(css: string): string
export function tablet(cssOrDevice: string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device ? DeviceMap[cssOrDevice as Device] : DeviceMap[Device.tablet]

  const cssProperties = css || cssOrDevice

  return `
    @media ${mediaQuery} {
      ${cssProperties}
    }
  `
}
export function phone(device: string, css: string): string
export function phone(css: string): string
export function phone(cssOrDevice: string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device ? DeviceMap[cssOrDevice as Device] : DeviceMap[Device.phone]

  const cssProperties = css || cssOrDevice

  return `
    @media ${mediaQuery} {
      ${cssProperties}
    }
  `
}