import { IconProps } from '@customTypes/Components'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const RightArrow: React.FC<IconProps> = ({ size }) => {
  const theme = useContext(ThemeContext)
  return (
    <svg width={size} height={size} viewBox="0 0 42 42" fill={theme.colors.icons} xmlns="http://www.w3.org/2000/svg" >
      <g clipPath="url(#clip0_19_275)">
        <path fillRule="evenodd" clipRule="evenodd" d="M37.5 20C37.5 15.3587 35.6563 10.9075 32.3744 7.62563C29.0925 4.34375 24.6413 2.5 20 2.5C15.3587 2.5 10.9075 4.34374 7.62564 7.62563C4.34375 10.9075 2.5 15.3587 2.5 20C2.5 24.6413 4.34375 29.0925 7.62563 32.3744C10.9075 35.6563 15.3587 37.5 20 37.5C24.6413 37.5 29.0925 35.6563 32.3744 32.3744C35.6563 29.0925 37.5 24.6413 37.5 20ZM1.74846e-06 20C2.21217e-06 14.6957 2.10714 9.60859 5.85787 5.85786C9.60859 2.10713 14.6957 -2.21217e-06 20 -1.74846e-06C25.3043 -1.28474e-06 30.3914 2.10713 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 1.28474e-06 25.3043 1.74846e-06 20ZM11.25 21.25C10.9185 21.25 10.6005 21.1183 10.3661 20.8839C10.1317 20.6495 10 20.3315 10 20C10 19.6685 10.1317 19.3505 10.3661 19.1161C10.6005 18.8817 10.9185 18.75 11.25 18.75L25.7325 18.75L20.365 13.385C20.2488 13.2688 20.1566 13.1308 20.0937 12.979C20.0308 12.8271 19.9984 12.6644 19.9984 12.5C19.9984 12.3356 20.0308 12.1729 20.0937 12.021C20.1566 11.8692 20.2488 11.7312 20.365 11.615C20.4812 11.4988 20.6192 11.4066 20.771 11.3437C20.9229 11.2808 21.0856 11.2484 21.25 11.2484C21.4144 11.2484 21.5771 11.2808 21.729 11.3437C21.8808 11.4066 22.0188 11.4988 22.135 11.615L29.635 19.115C29.7514 19.2311 29.8438 19.3691 29.9068 19.5209C29.9698 19.6728 30.0022 19.8356 30.0022 20C30.0022 20.1644 29.9698 20.3272 29.9068 20.4791C29.8438 20.6309 29.7514 20.7689 29.635 20.885L22.135 28.385C21.9003 28.6197 21.5819 28.7516 21.25 28.7516C20.9181 28.7516 20.5997 28.6197 20.365 28.385C20.1303 28.1503 19.9984 27.8319 19.9984 27.5C19.9984 27.1681 20.1303 26.8497 20.365 26.615L25.7325 21.25L11.25 21.25Z" fill={theme.colors.icons} />
      </g>
      <defs>
        <clipPath id="clip0_19_275">
          <rect width="40" height="40" fill={theme.colors.icons} transform="translate(40 40) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  )
}



