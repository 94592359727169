import { useFormAlarmClock } from '@hooks/useFormAlarmClock'
import { useGlobalModal } from '@hooks/useGlobalModal'
import { phone, pxToRem, tablet } from '@libs/index'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { CustomButton, CustomLink } from './Button'

type Inputs = {
  name: string,
  email: string,
  message: string,
  privacy: boolean
};

const ContactModal = styled.div`
  max-width: ${pxToRem(800)};
  width: 100%;
  ${tablet(`
    width: min(${pxToRem(800)}, 100%);
  `)}
`

const commonInputStyles = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.text};
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem(12)};
  font-size: ${pxToRem(21)};
  font-family: Poppins, Sans-Serif;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary}; 
    outline: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.badgeBackground};
  }
`

const CustomInput = styled.input`
  ${commonInputStyles}
`
const CustomTextArea = styled.textarea`
  ${commonInputStyles}
  resize: vertical;
  min-height: ${pxToRem(200)};
  column-span: 2;

`

const CustomCheckbox = styled.input`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  margin-right: ${pxToRem(8)};
`

const CustomBlock = styled.div<{ shouldSpan?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  ${({ shouldSpan }) => shouldSpan ? 'grid-column: 1 / -1' : null}
  ${tablet('grid-column: unset')}

`

const CustomLabel = styled.label`
${phone(`
    font-size: ${pxToRem(18)};    
  `)}
&:after {
  content: '*';
  margin-left: ${pxToRem(3)};
  color: ${({ theme }) => theme.colors.error};
}
`

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${tablet('grid-column: 1 / -1;')}
  ${phone(`
    flex-direction: column;
    row-gap: ${pxToRem(16)};
  `)}
`

const ErrorLabel = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${pxToRem(18)};
  grid-column: 1 / -1;
  ${phone(`
    font-size: ${pxToRem(14)};    
  `)}
`

const ContactForm = styled.form`
  display: grid;
  color: ${({ theme }) => theme.colors.text};
  grid-template-columns: repeat(auto-fit, minMax(320px, 1fr));
  gap: ${pxToRem(20)};
  row-gap: ${pxToRem(40)};
  padding: ${pxToRem(40)} ${pxToRem(20)};
`

const PrivacyPolicyBlock = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 40px 1fr;
  justify-content: flex-start;
  ${tablet('grid-column: 1 / -1;')}
  
  & > * { cursor: pointer;margin-bottom: ${pxToRem(5)};}

  line-height: 1rem;
`

export const BaseModal = () => {
  return <div></div>
}

interface FormFeedbackStatus {
  status: FormStatus;
}

const FormFeedback = ({ status }: FormFeedbackStatus) => {
  switch (status) {
    case 'LOADING':
      return <div>✉️ Sending email...</div>
    case 'SUCCESS':
      return <div>🚀 Congratulations! Your message has been sent!</div>
    case 'FAILED':
      return <div>🤯 Oh Snap! Something went wrong!</div>
    default:
      return null
  }
}

type FormStatus = 'SUCCESS' | 'FAILED' | 'LOADING' | null;

export const Modal = () => {
  const { t } = useTranslation()
  useFormAlarmClock()
  const [formState, setFormState] = useState<FormStatus>(null)
  const { close } = useGlobalModal()
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = data => {

    setFormState('LOADING')
    const body = {
      to: 'info@nicholaslazzerini.com',
      subject: 'Informazioni dal form del sito',
      form: data,
    }

    fetch('https://backend-utils.onrender.com/mailer/sendMessage', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(() => {
        setFormState('SUCCESS')
      })
      .catch(() => {
        setFormState('FAILED')
      })
  }

  const handleValidationErrors = (field: keyof Inputs) => {
    const error = errors[field]

    if (!error) return null

    switch (error.type) {
      case 'required':
        return <ErrorLabel>{t(`form.${field}.required`)}</ErrorLabel>
      case 'minLength':
        return <ErrorLabel>{t(`form.${field}.minLength`)}</ErrorLabel>
      case 'pattern':
        return <ErrorLabel >{t(`form.${field}.pattern`)}</ErrorLabel>
    }
  }

  // useEffect(() => {
  //   Object.keys(errors).length > 0 && setFormState('FAILED')
  // }, [errors])

  return <ContactModal>
    <ContactForm onSubmit={handleSubmit(onSubmit)}>
      {/* include validation with required or other standard HTML validation rules */}
      <CustomBlock>
        <CustomLabel htmlFor='name'>{t('form.name.label')}</CustomLabel>
        <CustomInput id='name' {...register('name',
          {
            required: true,
            minLength: 4
          }
        )} />
        {handleValidationErrors('name')}
      </CustomBlock>

      <CustomBlock>
        <CustomLabel htmlFor='email'>{t('form.email.label')}</CustomLabel>
        <CustomInput id='email' {...register('email', {
          required: true,
          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
        })} />
        {handleValidationErrors('email')}
      </CustomBlock>

      <CustomBlock shouldSpan>
        <CustomLabel htmlFor='message'>{t('form.message.label')}</CustomLabel>
        <CustomTextArea id='message' {...register('message', {
          required: true,
          minLength: 10
        })} />
        {handleValidationErrors('message')}
      </CustomBlock>
      <PrivacyPolicyBlock>
        <CustomCheckbox id='privacy' type='checkbox' {...register('privacy', { required: true })}></CustomCheckbox>
        <CustomLabel htmlFor='privacy'>{t('form.privacy.label')}</CustomLabel>
        {handleValidationErrors('privacy')}
      </PrivacyPolicyBlock>

      {formState && <FormFeedback status={formState} />}
      <FormFooter>
        <CustomLink to={''} onClick={() => close()}>{t('form.close')}</CustomLink>
        <CustomButton>{t('form.send')}</CustomButton>
      </FormFooter>

    </ContactForm>
  </ContactModal>
}

