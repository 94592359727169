interface ConfigInterface {
  FONT_SIZE: number;
  PAGE_PADDING: number;
  AVAILABLE_FOR_HIRE: boolean;
}

const config: ConfigInterface = {
  FONT_SIZE: 21,
  PAGE_PADDING: 50,
  AVAILABLE_FOR_HIRE: true,
}

export default config
