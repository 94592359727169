import { IconProps } from '@customTypes/Components'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const Close: React.FC<IconProps> = ({ size }) => {
  const theme = useContext(ThemeContext)
  return (<svg width={size} height={size} viewBox="0 0 46 46" fill={theme.colors.icons} xmlns="http://www.w3.org/2000/svg" >
    <path d="M32.128 16.6312C32.128 16.425 31.9593 16.2562 31.753 16.2562L28.6593 16.2703L23.9999 21.825L19.3453 16.275L16.2468 16.2609C16.0406 16.2609 15.8718 16.425 15.8718 16.6359C15.8718 16.725 15.9046 16.8094 15.9609 16.8797L22.0593 24.1453L15.9609 31.4063C15.9042 31.475 15.8728 31.561 15.8718 31.65C15.8718 31.8563 16.0406 32.025 16.2468 32.025L19.3453 32.0109L23.9999 26.4563L28.6546 32.0063L31.7483 32.0203C31.9546 32.0203 32.1233 31.8563 32.1233 31.6453C32.1233 31.5563 32.0905 31.4719 32.0343 31.4016L25.9452 24.1406L32.0437 16.875C32.0999 16.8094 32.128 16.7203 32.128 16.6312Z" fill={theme.colors.icons} />
    <path d="M24 3.04688C12.4031 3.04688 3 12.45 3 24.0469C3 35.6437 12.4031 45.0469 24 45.0469C35.5969 45.0469 45 35.6437 45 24.0469C45 12.45 35.5969 3.04688 24 3.04688ZM24 41.4844C14.3719 41.4844 6.5625 33.675 6.5625 24.0469C6.5625 14.4188 14.3719 6.60938 24 6.60938C33.6281 6.60938 41.4375 14.4188 41.4375 24.0469C41.4375 33.675 33.6281 41.4844 24 41.4844Z" fill={theme.colors.icons} />
  </svg>

  )
}




