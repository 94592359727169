import { useMediaQuery, useWindowResizeListener } from '@libs/index'
import React, { useEffect, useMemo, useState } from 'react'

export interface WindowDimensions {
	height: number
	width: number
}

interface NavigationContextData {
	landscapeNavigation: boolean
	windowDimensions: WindowDimensions
	isContactModalVisible: boolean
	triggerContactModal: any
}

export const NavigationContext = React.createContext<NavigationContextData>({
	landscapeNavigation: false,
	isContactModalVisible: false,
	triggerContactModal: () => null,
	windowDimensions: {
		width: 0,
		height: 0,
	},
})


export const NavigationProvider: React.FC = ({
	children,
}) => {
	const isLandscape = useMediaQuery('only screen and (orientation:landscape)')
	const windowDimensions = useWindowResizeListener()
	const landscapeNavigation = isLandscape || false
	const [isContactModalVisible, setIsContactModalVisible] = useState<boolean>(false)

	// Toggle class with landscape navigation
	useEffect(() => {
		document.body.classList.toggle('landscape-navigation', landscapeNavigation)
	}, [landscapeNavigation])

	const triggerContactModal = useMemo(() => { setIsContactModalVisible(!isContactModalVisible) }, [])


	return (
		<NavigationContext.Provider
			value={{
				landscapeNavigation,
				windowDimensions,
				isContactModalVisible,
				triggerContactModal
			}}
		>
			{children}
		</NavigationContext.Provider>
	)
}
