
import styled, { keyframes } from 'styled-components'

const Animation = keyframes`
	0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`

const LoaderContainer = styled.div`
	position: fixed;
	background: ${({ theme }) => theme.colors.body};
	pointer-events: none;
	overflow: hidden;
	top: 0;
	left:0;
	height: 100%;
	width: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
`

const LoaderSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`

const First = styled.div`
	position: absolute;
	border: ${({ theme }) => `4px solid ${theme.colors.primary}`};
	opacity: 1;
	border-radius: 50%;
	animation: ${Animation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`

const Second = styled.div`
	position: absolute;
	border: ${({ theme }) => `4px solid ${theme.colors.primary}`};
	opacity: 1;
	border-radius: 50%;
	animation: ${Animation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  	animation-delay: -0.5s;
`

export const Loading = () => (
  <LoaderContainer>
    <LoaderSpinner>
      <First></First>
      <Second></Second>
    </LoaderSpinner>
  </LoaderContainer>
)