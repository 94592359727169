import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { ReactLightsOff } from 'react-lights-off'
import { GithubIcon, LinkedinIcon, LogoIcon, MessageIcon } from '@assets/icons'
import { LanguageSelector } from '@components/LanguageSelector'
import { useClickOutside } from '@hooks/useClickOutside'
import { useGlobalModal } from '@hooks/useGlobalModal'
import { phone, pxToRem, tablet } from '@libs/index'
import { Turn as HamburgerIcon } from 'hamburger-react'
import { Link, NavLink } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { ThemeToggle } from './ThemeToggle'

interface HeaderProps {
  toggleTheme: (isDark: boolean) => void
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${pxToRem(115)};
  position: relative;
  z-index: 1;
`

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  ${tablet(`    
    width: 100%;
    justify-content: flex-end;
  `)}
`
const MenuContainer = styled.div`
  border-right: 3px solid ${({ theme }) => theme.colors.primary};
  padding-right: ${pxToRem(17)};
  text-transform: uppercase;
  height: 100%;
  max-height: 70px;
  align-items: center;
  display: flex;
  ${tablet(`    
    display: none;
  `)}
`
const SocialContainer = styled.div`
  border-right: 3px solid ${({ theme }) => theme.colors.primary};
  padding: 0 ${pxToRem(19)};
  height: 100%;
  max-height: 70px;
  align-items: center;
  display: flex;
  ${tablet(`    
    display: none;
  `)}

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    margin: 0 ${pxToRem(10)};
    cursor: pointer;
    ${tablet('margin: unset')}
  }

  a {
    height: inherit;
    display: flex;
    align-items: center;
    ${tablet(
  `
      &:first-of-type{
      margin: 0 ${pxToRem(10)} 0 0;
    }
    &:last-of-type{
      margin: 0  0 0 ${pxToRem(10)};
    }
      `
)}
    &:last-child {
      margin-right: 0%;
    }
  }
`

const SocialContainerMobile = styled.div`
  ${({ theme }) =>
    tablet(`
    display: flex;
    border-right: 0;
    border-top: 2px solid ${theme.colors.primary};
    margin: ${pxToRem(20)} 0 0;
    justify-content: space-around;
    align-items: flex-start;
    max-height: unset;
    height: 80px;
    padding: unset;
  `)}
  svg {
    fill: ${({ theme }) => theme.colors.primary};
    margin: 0 ${pxToRem(10)};
    cursor: pointer;
    ${tablet('margin: unset')}
  }

  a {
    height: inherit;
    display: flex;
    align-items: center;
    ${tablet(
      `
      &:first-of-type{
      margin: 0 ${pxToRem(10)} 0 0;
    }
    &:last-of-type{
      margin: 0  0 0 ${pxToRem(10)};
    }
      `
    )}
    &:last-child {
      margin-right: 0%;
    }
  }
`

const UtilsContainer = styled.div`
  padding-left: ${pxToRem(27)};
  height: 100%;
  max-height: 70px;
  align-items: center;
  display: flex;
  position: relative;

  /* > div {
    height: initial;
    display: flex;
  } */

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    /* padding: 0 ${pxToRem(16)} 0 ${pxToRem(8)}; */
    margin-right: ${pxToRem(16)};
    margin-left: ${pxToRem(-8)};
    cursor: pointer;
  }
`

export const HamburgerWrapper = styled.span`
  display: none;
  ${tablet(`    
    display: flex;
    margin-left: ${pxToRem(16)};
  `)}
`

export const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  padding: ${pxToRem(18)};
  height: 100%;
  display: flex;
  align-items: center;
  ${phone(`
    font-size: ${pxToRem(18)};    
  `)}

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const MobileNavigation = styled.div`
  display: none;
  position: relative;
  isolation: isolate;
  ${tablet(`
    display: flex;
    gap: ${pxToRem(16)}
  `)}
`

const MobileMenuDropdown = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.projectCardBackground};
  -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  padding: ${pxToRem(16)};
  border-radius: ${pxToRem(8)};
  top: ${pxToRem(50)};
  right: 0;
  min-width: ${pxToRem(250)};
  z-index: 2;
  text-transform: uppercase;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 9;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.projectCardBackground} transparent;
    border-width: 0 ${pxToRem(20)} ${pxToRem(20)};
    top: ${pxToRem(-12)};
    right: ${pxToRem(2)};
    margin-left: ${pxToRem(-20)};
  }
`

const ThemeToggleWrapper = styled.div`
   ${phone(`
    display: none;
  `)}
`

const LogoLink = styled(Link)`
  margin-left: ${pxToRem(-16)};
`

export const Header: React.FC<HeaderProps> = ({ toggleTheme }) => {
  const { t } = useTranslation()
  const { open } = useGlobalModal()
  const mobileMenuref = useRef(null)
  const theme = useContext(ThemeContext)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [showLanguageList, setShowLanguageList] = useState<boolean>(false)
  const handleThemeSwitcher = (isDark: boolean) => {
    toggleTheme && toggleTheme(isDark)
  }

  useClickOutside(mobileMenuref, () => setMobileMenu(false))

  return (
    <HeaderContainer>
      <LogoLink to="/home">
        <LogoIcon size={114} />
      </LogoLink>
      <Navigation>
        <MenuContainer>
          <StyledNavLink to="/home">{t('navigation.home')}</StyledNavLink>
          <StyledNavLink to="/projects/all">{t('navigation.projects')}</StyledNavLink>
          <StyledNavLink to="/about">{t('navigation.about')}</StyledNavLink>
        </MenuContainer>
        <SocialContainer>
          <a onClick={() => open()}>
            <MessageIcon size={40} />
          </a>
          <a href="https://github.com/nickimola" target="_blank" rel="noreferrer">
            <GithubIcon size={40} />
          </a>
          <a href="https://www.linkedin.com/in/nicholaslazzerini/" target="_blank" rel="noreferrer">
            <LinkedinIcon size={40} />
          </a>
          {/* <a
            href="https://www.linkedin.com/in/nicholaslazzerini/"
            target="_blank"
            rel="noreferrer"
          >
            <CoffeeIcon size={40} />
          </a> */}
        </SocialContainer>
        <UtilsContainer>
          {/* <div onClick={() => setShowLanguageList(!showLanguageList)}>
            <LanguageSelectorIcon size={40} />
          </div> */}
          <ThemeToggleWrapper>
            <ThemeToggle
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                handleThemeSwitcher(evt.currentTarget.checked)
              }}
            />
          </ThemeToggleWrapper>

          <LanguageSelector
            isVisible={showLanguageList}
            setLanguageDropdownState={setShowLanguageList}
          />
          <HamburgerWrapper>
            <HamburgerIcon
              size={40}
              direction="left"
              color={theme.colors.text}
              toggled={mobileMenu}
              rounded
              hideOutline
              onToggle={toggled => setMobileMenu(toggled)}
            />
          </HamburgerWrapper>
        </UtilsContainer>
      </Navigation>
      <MobileNavigation>
        {mobileMenu && (
          <MobileMenuDropdown ref={mobileMenuref}>
            <StyledNavLink to="/home" onClick={() => setMobileMenu(false)}>
              {t('navigation.home')}
            </StyledNavLink>
            <StyledNavLink onClick={() => setMobileMenu(false)} to="/projects/all">
              {t('navigation.projects')}
            </StyledNavLink>
            <StyledNavLink onClick={() => setMobileMenu(false)} to="/about">
              {t('navigation.about')}
            </StyledNavLink>
            <SocialContainerMobile>
              <a onClick={() => {
                setMobileMenu(false)
                open()
              }}>
                <MessageIcon size={40} />
              </a>
              <a href="https://github.com/nickimola" target="_blank" rel="noreferrer">
                <GithubIcon size={40} />
              </a>
              <a
                href="https://www.linkedin.com/in/nicholaslazzerini/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinIcon size={40} />
              </a>
              {/* <a
            href="https://www.linkedin.com/in/nicholaslazzerini/"
            target="_blank"
            rel="noreferrer"
          >
            <CoffeeIcon size={40} />
          </a> */}
            </SocialContainerMobile>
          </MobileMenuDropdown>
        )}
      </MobileNavigation>
    </HeaderContainer>
  )
}
