import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enLocale from './locale/en.json'
import itLocale from './locale/it.json'

const customLangDetector = {
  name: 'dw-lang-detector',
  lookup() {
    const lang = 'en'
    return lang
  },
}
const lngDetector = new LanguageDetector()
lngDetector.addDetector(customLangDetector)


const languages = {
  en: {
    translation: enLocale,
  },
  it: {
    translation: itLocale,
  },
}

export default i18n
  .use(lngDetector)
  .use(initReactI18next)
  .init({
    resources: languages,
    fallbackLng: 'en',
    debug: true,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      format: function (value, format) {
        if (format === 'highlight')
          return `<span class='highlighted'>${value}</span>`
        return value
      },
    },
  })
