import { useRef } from 'react'
import styled from 'styled-components'
import { useClickOutside } from '../hooks/useClickOutside'
import { pxToRem } from '../libs'

interface LanguageSelectorProps {
  isVisible: boolean;
  setLanguageDropdownState: (state: boolean) => void;
}

const LanguageSelectorDropdown = styled.div`
  position: absolute;
  min-height: ${pxToRem(50)};
  background: ${({ theme }) => theme.colors.projectCardBackground};
  -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  padding: ${pxToRem(16)};
  border-radius: ${pxToRem(8)};
  top: 100%;
  right: ${pxToRem(-26)};
  transform: translateX(-50%);
  min-width: ${pxToRem(150)};
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.projectCardBackground}
      transparent;
    border-width: 0 ${pxToRem(20)} ${pxToRem(20)};
    top: ${pxToRem(-12)};
    left: 50%;
    margin-left: ${pxToRem(-20)};
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    text-align: left;
    width: 100%;
    li {
      line-height: ${pxToRem(40)};
      width: 100%;
      cursor: pointer;
      margin-bottom: ${pxToRem(8)};
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
      &.active {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
      }
    }
  }
`

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  isVisible = false,
  setLanguageDropdownState,
}) => {
  const innerRef = useRef<HTMLDivElement | null>(null)
  // const { t } = useTranslation()
  // const { setLang } = useLanguage()
  useClickOutside(innerRef, () => setLanguageDropdownState(false))
  return isVisible ? (
    <LanguageSelectorDropdown ref={innerRef}>
      <ul>
        {/* <li
          onClick={() => setLang('en')}
          className={i18next.language === 'en' ? 'active' : ''}
        >
          {t('language.english')}
        </li>
        <li
          onClick={() => setLang('it')}
          className={i18next.language === 'it' ? 'active' : ''}
        > */}
        {/* {t('language.italian')}
        </li> */}
      </ul>
    </LanguageSelectorDropdown>
  ) : null
}
