import { useEffect } from 'react'

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  cb: () => void,
  addListener = true
) => {
  useEffect(() => {
    const handleAwayClick = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Element | null)) {
        cb()
      }
    }
    addListener && document.addEventListener('mousedown', handleAwayClick)
    addListener && document.addEventListener('touchstart', handleAwayClick)
    return () => {
      document.removeEventListener('mousedown', handleAwayClick)
      document.removeEventListener('touchstart', handleAwayClick)
    }
  }, [addListener, cb, ref])
}
