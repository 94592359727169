import { pxToRem } from '@libs/index'
import { createGlobalStyle, DefaultTheme } from 'styled-components'
import { modalStyle, scrollbar } from './common'

export const PAGE_PADDING_REM = pxToRem(50)

const commonTheme = {
	fontSize: 21,
	colors: {
		secondary: '#B24670',
		buttonText: '#F7FBFD',
		error: '#c95056'

	},
}

export const lightMode: DefaultTheme = {
	...commonTheme,
	colors: {
		...commonTheme.colors,
		pageBorder: '#e8ebec',
		primary: '#2c738b',
		text: '#121213',
		body: '#F7FBFD',
		icons: '#121213',
		projectCardBackground: '#ffffff',
		badgeBackground: '#c9dee6'
	},
}

export const darkMode: DefaultTheme = {
	...commonTheme,
	colors: {
		...commonTheme.colors,
		pageBorder: '#000',
		primary: '#42859e',
		body: '#121213',
		text: '#F7FBFD',
		icons: '#F7FBFD',
		buttonText: '#101315',
		projectCardBackground: '#202020',
		badgeBackground: '#222222'
	},
}

export const GlobalStyles = createGlobalStyle`
	${scrollbar}
	${modalStyle}
	@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
	}

	@font-face {
	font-family: "PoppinsBold";
	src: local("Poppins"),
		url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
	}

	* {
    	box-sizing: border-box;
		&:focus {
			box-shadow: ${props => `inset 0 0 0 1px ${props.theme.colors.primary}`};
			outline: 0;
		}
  	}
  

	html, body {
		margin: 0;
		padding: 0;
		font-size: ${({ theme }) => theme.fontSize}px;
		font-family: Poppins, Sans-Serif;
		background: ${({ theme }) => theme.colors.body};
		color: ${({ theme }) => theme.colors.text}
	}

	html, body, #root {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	#root {
	
		height: 100dvh;
  		width: 100dvw;
		
	}

	outline {
		
	}

	.highlighted{
		color: ${({ theme }) => theme.colors.primary}
	}

	a {
		height: initial;
		display: flex;
		text-decoration: none;
	}

	p {
		margin: 0
	}


`
