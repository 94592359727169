import { phone, pxToRem, tablet } from '@libs/index'
import styled, { css } from 'styled-components'

export const scrollbar = css`
  ::-webkit-scrollbar {
    display: initial !important;
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.badgeBackground};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 5px;
  }
`

export const Title = styled.h1`
  font-size: ${pxToRem(36)};
  ${phone(`
    font-size: ${pxToRem(20)};    
  `)}
`
export const SecondaryTitle = styled.h2`
  font-size: ${pxToRem(28)};
  ${phone(`
    font-size: ${pxToRem(18)};    
  `)}
`

export const modalStyle = css`
  .contact-modal {
    background-color: #0009 !important;
    .modal-wrapper {
      width: min(${pxToRem(800)}, 100%) !important;
      ${tablet(`
        width: max(400px, 80%)!important;
      `)}
      ${phone(`
        width: max(300px, 100%)!important;
      `)}
    }
    .modal-wrapper .modalContent {
      background: ${({ theme }) => theme.colors.body};
      border-radius: ${pxToRem(12)};
      ${phone(`
      max-height: 100vh;
      height: 100vh;
  `)}
    }
    .modal-body {
      width: 100% !important;
    }
  }
`
