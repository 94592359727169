import { useEffect, useState } from 'react'
export const useDarkMode = () => {
  const [theme, setTheme] = useState<string>('light')

  const setMode = (mode: string) => {
    window.localStorage.setItem('nicholaslazzerini.com/theme', mode)
    setTheme(mode)
  }

  useEffect(() => {
    const userTheme =
      window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    const localTheme = window.localStorage.getItem(
      'nicholaslazzerini.com/theme'
    )
    setTheme(localTheme || userTheme || 'light')
  }, [])

  return { theme, setMode }
}
